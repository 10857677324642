<template>
  <main>
    <div class="container">
      <h1 class="page-title_hidden" id="page-title-for-sr">{{ $t('error.notFoundError.pageTitle') }}</h1>
      <div class="columns">
        <div class="column-full">
          <div class="content error-wrapper NOT_FOUND_ERROR">
            <div class="error-icon-wrapper">
              <not-found-icon />
            </div>
            <error-template />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" src="../assets/scss/components/Error.scss"></style>

<script>
import { ERROR_CODES_NAMES } from '@/base/const/errorCodes.const'
import { mapMutations } from 'vuex'
import NotFoundIcon from '@/base/components/Error/Icons/404Icon'
import ErrorTemplate from '@/base/components/Error/Template'
import { pageTitle } from '@/base/const/routing.const'

export default {
  name: 'NotFound',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().errorNotFound
    }
  },
  components: {
    ErrorTemplate,
    NotFoundIcon
  },
  methods: {
    ...mapMutations([
      'SET_ERROR_DATA'
    ])
  },
  created () {
    this.SET_ERROR_DATA({
      errorCode: ERROR_CODES_NAMES.NOT_FOUND_ERROR,
      errorUrl: null
    })
  }
}
</script>
